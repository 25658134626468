import store from "../../store";

export default async () => {
  const selectedOrganization = await store.getters['organization/selectedOrganization']

  return [
    {
      id: "internal:calculator",
      title: "Calculadora",
      description:
        "Faça cálculos de forma rápida e prática sem precisar sair da Atletic.Me.",
      keywords: ["calculadora", "calcular", "cálculo", "conta"],
      type: "calculator",
      relevantIn: ["all"],
      icon: "mdi-calculator-variant",
    },
    {
      id: "external:mp-taxes",
      title: "Como configurar as taxas e prazo de recebimento?",
      description:
        "Acesse as configurações de recebimento do Mercado Pago e configure as taxas e prazo de recebimento.",
      keywords: ["taxa", "prazo de recebimento", "mercado pago"],
      type: "external",
      permission: [256],
      relevantIn: ["/admin/organization/profile"],
      data: {
        url: "https://www.mercadopago.com.br/costs-section/release-options/edit/merchant-services",
      },
    },
    {
      id: "external:meet",
      title: "Sala de reuniões",
      description:
        "Acesse a sala de reuniões da sua organização.",
      keywords: ["reunião", "meet", "chamada", "ligação"],
      type: "external",
      permission: [8, 256,],
      relevantIn: ["/admin/organization/profile", "/admin/organization"],
      data: {
        url: `https://meet.atleticme.app/${selectedOrganization.slug}`,
      },
      icon: "mdi-laptop-account",
    },
  ];

}